import { Action, createReducer, createFeatureSelector, createSelector } from '@ngrx/store';
import { Cart } from '@shared/models/cart';
import { LoadingState } from '@shared/types/loading-state';
import { mutableOn } from 'ngrx-etc';
import { loadCart, loadCartFailure, loadCartSuccess } from '../actions/load-cart.actions';
import { createCart, createCartFailure, createCartSuccess } from '../actions/create-cart.actions';
import { addCartItem, addCartItemFailure, addCartItemSuccess } from '../actions/add-cart-item.actions';
import { removeCartItem, removeCartItemFailure, removeCartItemSuccess } from '../actions/remove-cart-item.actions';
import {
  updateCartItemQuantity,
  updateCartItemQuantityFailure,
  updateCartItemQuantitySuccess,
} from '../actions/update-cart-item-quantity.actions';
import {
  updateCartItemProduct,
  updateCartItemProductFailure,
  updateCartItemProductSuccess,
} from '../actions/update-cart-item-product.actions';
import {
  addCartItemDiscount,
  addCartItemDiscountFailure,
  addCartItemDiscountSuccess,
} from '../actions/add-cart-item-discount.actions';
import {
  removeCartItemDiscount,
  removeCartItemDiscountFailure,
  removeCartItemDiscountSuccess,
} from '../actions/remove-cart-item-discount.actions';
import { createQuotes, createQuotesFailure, createQuotesSuccess } from '../actions/create-quotes.actions';
import { createOrder, createOrderFailure } from '../actions/create-order.actions';
import { setCartConfig } from '../actions/set-cart-config.actions';
import { selectCartCustomer, selectCartCustomerValidations, selectCartItemId } from './cart-config.reducer';
import { PricingUpdateType } from '@shared/types/update-type';
import { selectAllProducts, selectOfferedProducts } from '@features/products/shared/reducers/products.reducer';
import { loadQuoteFailure, loadQuoteSuccess } from '@features/quotes/shared/actions/load-quote.actions';
import {
  updateCustomer,
  updateCustomerFailure,
  updateCustomerSuccess,
} from '@features/customers/admin/actions/update-customer.actions';
import { setCustomExpirationDate } from '../actions/set-custom-expiration-date.actions';
import { updateSelectedPeriods } from '../actions/update-selected-periods.actions';

export const featureKey = 'cart';

export interface State {
  loadingState: LoadingState;
  loadingText: string | undefined;
  cart: Cart | undefined;
  selectedPeriods: PricingUpdateType[];
  customExpirationDate: string | undefined;
}

export const initialState: State = {
  loadingState: 'not_loading',
  loadingText: undefined,
  cart: undefined,
  selectedPeriods: [],
  customExpirationDate: undefined,
};

export const cartReducer = createReducer(
  initialState,
  mutableOn(loadCart, createCart, (state, {}) => {
    state.loadingText = undefined;
    state.loadingState = 'loading';
  }),
  mutableOn(addCartItem, (state) => {
    state.loadingState = 'loading';
    state.loadingText = 'Adding Subscription...';
    state.selectedPeriods = [];
  }),
  mutableOn(removeCartItem, (state) => {
    state.loadingState = 'loading';
    state.loadingText = 'Removing Subscription...';
  }),
  mutableOn(updateCartItemQuantity, (state) => {
    state.loadingState = 'loading';
    state.loadingText = 'Updating Quantity...';
  }),
  mutableOn(updateCartItemProduct, (state) => {
    state.loadingState = 'loading';
    state.loadingText = 'Changing Subscription...';
    state.selectedPeriods = [];
  }),
  mutableOn(addCartItemDiscount, (state) => {
    state.loadingState = 'loading';
    state.loadingText = 'Applying Promotion...';
  }),
  mutableOn(removeCartItemDiscount, (state) => {
    state.loadingState = 'loading';
    state.loadingText = 'Removing Promotion...';
  }),
  mutableOn(updateCustomer, (state) => {
    state.loadingState = 'loading';
    state.loadingText = 'Updating Customer...';
  }),
  mutableOn(createQuotes, (state, { quote_type, update_types }) => {
    state.loadingState = 'loading';
    state.loadingText =
      quote_type == 'pricing'
        ? `Creating Estimate${update_types.length > 1 ? 's' : ''}...`
        : `Creating Quote${update_types.length > 1 ? 's' : ''}...`;
  }),
  mutableOn(createOrder, (state, { quote_type }) => {
    state.loadingState = 'loading';
    state.loadingText =
      quote_type == 'nfr'
        ? 'Creating NFR License...'
        : quote_type == 'trial'
        ? 'Creating Trial License...'
        : 'Placing Order...';
  }),
  mutableOn(
    loadCartSuccess,
    createCartSuccess,
    addCartItemSuccess,
    removeCartItemSuccess,
    updateCartItemQuantitySuccess,
    updateCartItemProductSuccess,
    addCartItemDiscountSuccess,
    removeCartItemDiscountSuccess,
    (state, { cart }) => {
      state.loadingState = 'loaded';
      state.loadingText = undefined;
      state.cart = cart;
      if (['enlarge', 'upgrade', 'trial', 'nfr'].includes(cart?.quote_type!)) {
        state.selectedPeriods = [cart?.cart_items[0].update_type as PricingUpdateType];
      }
    }
  ),
  mutableOn(updateCustomerSuccess, (state) => {
    state.loadingState = 'loaded';
    state.loadingText = undefined;
  }),
  mutableOn(loadQuoteSuccess, createQuotesSuccess, (state, {}) => {
    state.loadingState = 'not_loading';
    state.loadingText = undefined;
    state.cart = undefined;
    state.customExpirationDate = undefined;
    state.selectedPeriods = [];
  }),
  mutableOn(setCartConfig, (state, { clearCart }) => {
    if (clearCart) {
      state.cart = undefined;
      state.loadingText = undefined;
      state.customExpirationDate = undefined;
      state.selectedPeriods = [];
    }
  }),
  mutableOn(
    loadCartFailure,
    createCartFailure,
    addCartItemFailure,
    removeCartItemFailure,
    updateCartItemQuantityFailure,
    updateCartItemProductFailure,
    addCartItemDiscountFailure,
    removeCartItemDiscountFailure,
    createQuotesFailure,
    createOrderFailure,
    loadQuoteFailure,
    updateCustomerFailure,
    (state, {}) => {
      state.loadingState = 'not_loading';
      state.loadingText = undefined;
    }
  ),
  mutableOn(setCustomExpirationDate, (state, { customExpirationDate }) => {
    state.customExpirationDate = customExpirationDate;
  }),
  mutableOn(updateSelectedPeriods, (state, { period }) => {
    if (state.selectedPeriods.includes(period)) {
      state.selectedPeriods = state.selectedPeriods.filter((selectedPeriod) => selectedPeriod !== period);
    } else {
      state.selectedPeriods.push(period);
    }
  })
);

export function reducer(state: State | undefined, action: Action) {
  return cartReducer(state, action);
}

export const cartFeatureSelector = createFeatureSelector<State>(featureKey);

export const selectCartLoadingState = createSelector(cartFeatureSelector, (state) => state.loadingState);

export const selectCartLoadingText = createSelector(cartFeatureSelector, (state) => state.loadingText);

export const selectCart = createSelector(cartFeatureSelector, (state) => state.cart);

export const selectCustomExpirationDate = createSelector(cartFeatureSelector, (state) => state.customExpirationDate);

export const selectSelectedPeriods = createSelector(cartFeatureSelector, (state) => state.selectedPeriods);

export const selectSelectedCartItem = createSelector(selectCart, selectCartItemId, (cart, cartItemId) =>
  cart?.cart_items.find((cartItem) => cartItem.id === cartItemId)
);

export const selectCartTotal = (pricingUpdateType: PricingUpdateType) =>
  createSelector(selectCart, (cart) => cart?.totals[pricingUpdateType]);

export const selectAllCartItemsWithDiscountsForPeriod = (pricingUpdateType: PricingUpdateType) =>
  createSelector(selectCart, (cart) =>
    cart?.cart_items.filter((cartItem) => !!cartItem.pricing[pricingUpdateType]?.discount)
  );
export const selectAllCartItemsProductCodes = createSelector(selectCart, (cart) =>
  cart?.cart_items.map((cartItem) => cartItem.product_code)
);

export const selectCartTotalCartItemProductsWithPricing = (pricingUpdateType: PricingUpdateType) =>
  createSelector(selectCart, selectAllProducts, (cart, products) =>
    !cart || !products
      ? []
      : cart.cart_items.map((cartItem) => ({
          cartItem,
          cartItemPricing: cartItem.pricing[pricingUpdateType]!,
          product: products.find((product) => product.attributes.code === cartItem.product_code)!,
        }))
  );

export const selectDxApprovalNeededForPeriods = createSelector(selectCart, (cart) => {
  let pricingUpdateTypes: PricingUpdateType[] = ['year_1', 'year_2', 'year_3'];
  return !cart
    ? []
    : pricingUpdateTypes.filter(
        (pricingUpdateType) =>
          cart.cart_items.filter((cartItem) => !!cartItem.pricing[pricingUpdateType]?.dx_approval_type).length > 0
      );
});

export const selectSkipDxQuoteAvailableForPeriods = createSelector(
  selectDxApprovalNeededForPeriods,
  selectCart,
  (dxApprovalNeededPeriods, cart) => {
    return !cart || !dxApprovalNeededPeriods
      ? false
      : dxApprovalNeededPeriods.filter(
          (pricingUpdateType) =>
            cart.cart_items.filter(
              (cartItem) =>
                !!cartItem.pricing[pricingUpdateType]?.dx_approval_type &&
                cartItem.pricing[pricingUpdateType]?.discount?.attributes?.discount_type == 'custom'
            ).length > 0
        ).length > 0;
  }
);

export const selectHasEsetServicesInCart = createSelector(selectCart, (cart) => {
  return !cart
    ? false
    : cart.cart_items.filter((cartItem) => cartItem.product_provisioning_type == 'eset_services').length > 0;
});

export const selectHasServicesValidationErrors = createSelector(
  selectHasEsetServicesInCart,
  selectCartCustomerValidations,
  selectCart,
  (hasEsetServicesInCart, cartCustomerValidations, cart) => {
    return (
      hasEsetServicesInCart &&
      Object.values(cartCustomerValidations || false).includes(true) &&
      cart?.quote_type !== 'pricing'
    );
  }
);

export const selectFirstCartItemProduct = createSelector(selectCart, selectOfferedProducts, (cart, products) =>
  products.find((product) => cart?.cart_items[0]?.product_code == product.attributes.code)
);

export const selectCartLargeQuantityValidations = createSelector(
  selectCart,
  selectCartCustomerValidations,
  selectCartCustomer,
  (cart, cartCustomerValidations, cartCustomer) => {
    return !cart
      ? false
      : Object.values(cartCustomerValidations || false).includes(true) &&
          cart.cart_items.reduce((acc, cartItem) => acc + cartItem.quantity, 0) >= 250;
  }
);

export const selectCorrectCustomerEmailAddressRequiredForCart = createSelector(
  selectCart,
  selectCartCustomerValidations,
  (cart, cartCustomerValidations) => {
    return !cart
      ? false
      : cart.cart_items.filter((cart_item) => ['147', '148', '149'].includes(cart_item.product_code)).length > 0;
  }
);

export const selectValidationForCart = createSelector(
  selectCart,
  selectCartCustomerValidations,
  selectCartLargeQuantityValidations,
  selectHasServicesValidationErrors,
  (cart, cartCustomerValidations, largeQuantityValidation, hasServicesValidationErrors) => {
    return {
      ...cartCustomerValidations,
      largeQuantityWithInvalidEntity: largeQuantityValidation,
      hasServicesError: hasServicesValidationErrors,
    };
  }
);
